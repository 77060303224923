import _ from 'lodash';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { SectionHead } from 'src/components/cards';
import { SHOW_COMMEMORATE } from 'src/constants';
import DynamicAds, { KEY_ADS_POSITION } from 'src/utils/ads/dynamic-ads';
import { DATALAYER_TYPE } from 'src/utils/datalayer/send-datalayer';
import styled from 'styled-components';

const StickyBox = dynamic(import('react-sticky-box'));
const WidgetTags = dynamic(import('src/components/widgets/widget-tags'));
const SectionFull = dynamic(import('src/components/sections/section-full'));
const CardH = dynamic(import('src/components/cards/card-h'));
const CardV = dynamic(import('src/components/cards/card-v'));
const Carousels = dynamic(import('src/components/carousels'));

const SectionOne = ({ ads, sectionId = 'section-2', data = {} }) => {
  if (_.isEmpty(data)) return null;
  const router = useRouter();

  const { block1, block2, block3, headerLatestNews, newHighlight, tags } = data;

  const isTabletOrMobile = useMediaQuery({ maxWidth: 690 });
  const isDesktop = useMediaQuery({ minWidth: 691 });
  const cloneLatestNews = {
    heading: 'LAST UPDATE',
    link: '/category/latest-news',
    data: _.isArray(headerLatestNews) && (isTabletOrMobile ? [...headerLatestNews.slice(0, 4)] : [...headerLatestNews.slice(0, 5)])
  };
  const useResponsive = () => {
    const [isClient, setIsClient] = useState(false);

    const isMobile = useMediaQuery({
      maxWidth: '690px'
    });

    const isTabletMini = useMediaQuery({
      maxWidth: '768px'
    });
    const isTabletPro = useMediaQuery({
      minWidth: '769px'
    });

    const isLaptop = useMediaQuery({
      minWidth: '691px'
    });

    const isDesktop = useMediaQuery({
      minWidth: '1024px'
    });

    useEffect(() => {
      if (typeof window !== 'undefined') setIsClient(true);
    }, []);

    return {
      isDesktop: isClient ? isDesktop : true,
      isLaptop: isClient ? isLaptop : true,
      isTabletMini: isClient ? isTabletMini : false,
      isTabletPro: isClient ? isTabletPro : false,
      isMobile: isClient ? isMobile : false
    };
  };
  const responsive = useResponsive();

  return (
    <>
      <SectionWrapper style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
        {/* --------------------------------- BLOCK HIGHTLIGTH -------------------------------- */}
        {!_.isEmpty(newHighlight) && <SectionFull data={newHighlight} />}
        <div className='container' id='section-2'>
          <div className='row'>
            <div className='block-latestnews col-12 col-lg-3'>
              <StickyBox offsetTop={30} offsetBottom={30}>
                {/* --------------------------------- LASTEST NEWS -------------------------------- */}
                {!_.isEmpty(cloneLatestNews?.data) && (
                  <>
                    <SectionHead data={cloneLatestNews?.heading} link={cloneLatestNews?.link} />
                    <div className='row card-list'>
                      {cloneLatestNews?.data?.map((item, index) => (
                        <>
                          {/* <div className='mb-15 col-12 col-md-6 col-lg-12'> */}
                          {isDesktop && (
                            <div key={index} className='mb-15 col-12 col-md-6 col-lg-12'>
                              <CardH
                                data={item}
                                pinTag={false}
                                //! DATALAYER
                                eventDataLayer={{
                                  type: DATALAYER_TYPE.TRACK_INDEX,
                                  router: router?.pathname,
                                  section: sectionId,
                                  data: {
                                    block: '1',
                                    title: item?.title,
                                    heading: cloneLatestNews?.heading,
                                    index: index + 1
                                  }
                                }}
                              />
                            </div>
                          )}
                          {/* </div> */}

                          {isTabletOrMobile && (
                            <div key={index} className='col-6 card-list-v'>
                              <CardV
                                data={item}
                                // pinTag={false}
                                showByline={false}
                                //! DATALAYER
                                eventDataLayer={{
                                  type: DATALAYER_TYPE.TRACK_INDEX,
                                  router: router?.pathname,
                                  section: sectionId,
                                  data: {
                                    block: '4',
                                    title: item?.title,
                                    heading: cloneLatestNews?.heading,
                                    index: index + 1
                                  }
                                }}
                              />
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  </>
                )}
                {/* ----------------------------- WIDGET_TAG ---------------------------- */}
                {!_.isEmpty(tags) && (
                  <WidgetTags
                    data={tags}
                    //! DATALAYER
                    eventDataLayer={{
                      type: DATALAYER_TYPE.TRACK_POSITION,
                      router: router?.pathname,
                      section: sectionId,
                      position: `after:${cloneLatestNews?.heading}`,
                      data: {
                        block: isDesktop ? '4' : '5'
                      }
                    }}
                  />
                )}
              </StickyBox>
            </div>

            {/* --------------------------------- BLOCK 1 -------------------------------- */}
            {!_.isEmpty(block1?.data) && (
              <div className='block-1 col-12 col-lg-6'>
                <SectionHead data={block1.title} link={block1.link} />
                <div className='row'>
                  {block1.data.slice(0, 2).map((item, index) => (
                    <div key={index} className='border-item col-12 col-lg-12 card-list'>
                      <CardV
                        data={item}
                        showBlurb={true}
                        // pinTag={!isDesktop}
                        //! DATALAYER
                        eventDataLayer={{
                          type: DATALAYER_TYPE.TRACK_INDEX,
                          router: router?.pathname,
                          section: sectionId,
                          data: {
                            block: isDesktop ? '2' : '1',
                            title: item?.title,
                            heading: block1.title,
                            index: index + 1
                          }
                        }}
                      />
                    </div>
                  ))}
                  {/* ---------------------- ADS : PPN - INARTICLE_1 [DESKTOP]-------------------- */}
                  <div className='ads-fix-position'>{isDesktop && <DynamicAds position={KEY_ADS_POSITION.INARTICLE_1} data={ads} />}</div>

                  {block1.data.slice(2, 5).map((item, index) => (
                    <div key={index} className='border-item col-12 col-lg-12 card-list'>
                      <CardV
                        data={item}
                        showBlurb={true}
                        // pinTag={!isDesktop}
                        //! DATALAYER
                        eventDataLayer={{
                          type: DATALAYER_TYPE.TRACK_INDEX,
                          router: router?.pathname,
                          section: sectionId,
                          data: {
                            block: isDesktop ? '2' : '1',
                            title: item?.title,
                            heading: block1.title,
                            index: index + 1
                          }
                        }}
                      />
                    </div>
                  ))}

                  {/* ---------------------- ADS : PPN - INARTICLE_2 [DESKTOP]-------------------- */}
                  {isDesktop && <DynamicAds position={KEY_ADS_POSITION.INARTICLE_2} data={ads} />}
                </div>
              </div>
            )}
            {/* ---------------------- ADS : PPN - INARTICLE_1 [MOBILE]-------------------- */}
            {isTabletOrMobile && <DynamicAds position={KEY_ADS_POSITION.INARTICLE_1} data={ads} />}

            {!_.isEmpty(block2?.data) && (
              <div className='col-12 col-lg-3 mt-30 mt-lg-0'>
                <StickyBox offsetTop={30} offsetBottom={50}>
                  {/* --------------------------------- BLOCK 2-------------------------------- */}
                  <div className='block-2'>
                    <SectionHead data={block2?.title} link={block2?.link} />
                    <Carousels
                      infinite={block2.data.length >= 2}
                      dots={false}
                      slideShow={1}
                      autoplaySpeed={5000}
                      mobileSlideShow={block2.data.length > 1 ? 2 : 1}
                    >
                      {block2.data.map((item, index) => (
                        <div key={index} tabIndex='-1'>
                          <CardV
                            data={item}
                            path={item?.link}
                            // pinTag={!isDesktop}
                            showCountview={false}
                            //! DATALAYER
                            eventDataLayer={{
                              type: DATALAYER_TYPE.TRACK_INDEX,
                              router: router?.pathname,
                              section: sectionId,
                              data: {
                                block: isDesktop ? '3' : '2',
                                title: item?.title,
                                heading: block2.title,
                                carousel: index + 1
                              }
                            }}
                          />
                        </div>
                      ))}
                    </Carousels>
                  </div>
                  {/* ---------------------- ADS : PPN - INARTICLE_2 [MOBILE]-------------------- */}
                  {isTabletOrMobile && <DynamicAds position={KEY_ADS_POSITION.INARTICLE_2} data={ads} />}

                  {/* --------------------------------- BLOCK 3-------------------------------- */}
                  {!_.isEmpty(block3?.data) && (
                    <>
                      <div className='block-3'>
                        <SectionHead data={block3?.title} link={block3?.link} />
                        <Carousels dots={false} slideShow={1} mobileSlideShow={block3.data.length > 1 ? 2 : 1}>
                          {block3.data.map((item, index) => (
                            <div key={index}>
                              <CardV
                                data={item}
                                path={item?.link}
                                //  pinTag={!isDesktop}
                                showCountview={false}
                                //! DATALAYER
                                eventDataLayer={{
                                  type: DATALAYER_TYPE.TRACK_INDEX,
                                  router: router?.pathname,
                                  section: sectionId,
                                  data: {
                                    block: isDesktop ? '5' : '3',
                                    title: item?.title,
                                    heading: block2.title,
                                    carousel: index + 1
                                  }
                                }}
                              />
                            </div>
                          ))}
                        </Carousels>
                        {/* ----------------------------- ADS : STICKY-1 -------------------------- */}
                        <DynamicAds position={KEY_ADS_POSITION.STICKY_1} data={ads} />
                      </div>
                    </>
                  )}
                </StickyBox>
              </div>
            )}
          </div>
        </div>
      </SectionWrapper>
    </>
  );
};
const AdsElectionIframe = styled.div`
  .container {
    margin: auto;
  }
  .ads-election-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
  }
`;
const SectionWrapper = styled.section`
  position: relative;
  display: block;

  .ads-fix-position {
    @media (max-width: 768px) {
      display: none;
    }
  }
  @media (max-width: 690px) {
    .article-share {
      display: none;
    }
  }

  .byline {
    color: #000000;
  }

  /* --------------------------------- BLOCK-LATEST -------------------------------- */
  /* .section-head {
    padding-bottom: 24px;
    @media (max-width: 690px) {
      padding-bottom: 15px;
    }
  } */
  .card-list {
    padding: 0 0 42px 0;
  }
  .block-latestnews {
    .share-card,
    .video-card-share {
      padding: 5px;
    }
    @media (max-width: 768px) {
      order: 3;
      .card-list-v {
        .card-thumb .img-scale-animate .share-card {
          display: none;
        }
      }
    }
    @media (max-width: 690px) {
      .card-list {
        margin: 0 -10px;
        padding: 0 0 32px 0;
        .card-list-v {
          padding: 0 10px;
          .border-item {
            border-bottom: 1px solid rgba(120, 120, 120, 0.1);
            padding: 0 0 24px 0;
            margin-bottom: 24px;
          }
        }
      }
    }
  }
  /* --------------------------------- BLOCK-1 -------------------------------- */
  .block-1 {
    padding: 0 0 0 30px;

    .border-item {
      margin-bottom: 38px;

      @media (max-width: 690px) {
        margin-bottom: 20px;
      }
      &.card-list {
        padding: 0;
      }
      @media (max-width: 768px) {
        &:not(:nth-child(1)) {
          &.card-list {
            .border-item {
              margin-bottom: 0;
              border-bottom: 1px solid rgba(120, 120, 120, 0.1);
              padding: 0 0 20px 0;

              .card-thumb .img-scale-animate .share-card {
                display: none;
              }
            }
          }
        }
      }
      @media (max-width: 480px) {
        &:not(:nth-child(1)) {
          .byline {
            display: none;
          }
        }
      }
    }

    .card-content {
      h3 {
        font-size: 28px;
        font-weight: 500;
      }
    }
    @media (max-width: 768px) {
      padding: 0 0 0 0;
      .border-item {
        &:nth-child(1) {
          .card-content {
            h3 {
              font-size: 20px;
            }
          }
        }
        &:not(:nth-child(1)) {
          border: none;
          padding: 0;
          width: 50%;
          .card-content {
            /* .byline {
              display: none;
            } */
            h3 {
              font-size: 16px;
              line-height: 1.45;
            }
          }
        }

        &:nth-child(2) {
          padding-right: 10px;
        }
        &:nth-child(3) {
          padding-left: 10px;
        }
        &:nth-child(4) {
          padding-right: 0;
        }
        &:nth-child(5) {
          padding-right: 10px;
        }
      }
    }
    @media (max-width: 690px) {
      .blurb {
        display: none;
      }
    }
  }

  .block-2,
  .block-3 {
    .slick-slider {
      position: static;
      .slide-arrow,
      .slick-arrow {
        display: block !important;
        background-color: #fff;
        border: none;
        position: absolute;
        padding-left: 5px;
        top: 16px;
        right: 0;
      }
      .next-arrow,
      .slick-next,
      .prev-arrow,
      .slick-prev {
        z-index: 1;
        font-size: unset;
        width: unset;
        height: unset;
        &:before {
          content: unset;
        }
        cursor: pointer;
        svg {
          vertical-align: middle;
          color: #000;
        }
      }
      .prev-arrow,
      .slick-prev {
        right: 20px;
        left: unset;
        z-index: 2;
        &:after {
          content: '/';
          padding-left: 5px;
          color: #a0a0a0;
          vertical-align: middle;
        }
      }
    }
    @media (max-width: 690px) {
      .slick-slider {
        position: relative;
        .slide-arrow,
        .slick-arrow {
          background-color: #000;
          position: relative;
          padding: 14px;
        }
        .next-arrow,
        .slick-next,
        .prev-arrow,
        .slick-prev {
          position: absolute;
          top: 30%;
          transform: translate(0, -30%);
          svg {
            vertical-align: middle;
            polyline {
              stroke: #ffe000;
            }
          }
        }
        .next-arrow,
        .slick-next {
          right: -2px;
        }
        .prev-arrow,
        .slick-prev {
          right: unset;
          left: -2px;
          z-index: 1;
          &:after {
            content: '';
          }
        }
      }
    }
  }
  /* --------------------------------- BLOCK-2 -------------------------------- */
  .block-2 {
    position: relative;
    padding: 0 0 0 30px;
    /* @media (min-width: 767px) {
      .tag-pin {
        display: none;
      }
    } */
    .card-v {
      h3 {
        font-size: 18px;
        line-height: 1.45;
        font-weight: 400;
      }
    }
    @media (max-width: 992px) {
      padding: 0;
      .custom-carousel {
        margin: 0 -15px;
      }
      .card-v {
        .card-content {
          border-bottom: 1px solid rgba(120, 120, 120, 0.1);
          padding: 0 0 25px 0;
        }
        padding: 0 15px;
      }
    }
  }
  /* --------------------------------- BLOCK-3 -------------------------------- */
  .block-3 {
    position: relative;
    padding: 0 0 0 30px;
    /* @media (min-width: 767px) {
      .tag-pin {
        display: none;
      }
    } */
    @media (max-width: 768px) {
      padding: 0;
    }
    .card-v {
      h3 {
        font-size: 18px;
        line-height: 1.45;
        font-weight: 400;
      }
    }
    @media (max-width: 992px) {
      .custom-carousel {
        margin: 0 -15px;
      }
      .card-v {
        .card-content {
          border-bottom: 1px solid rgba(120, 120, 120, 0.1);
          padding: 0 0 25px 0;
        }
        padding: 0 15px;
      }
    }
  }

  .side-right {
    @media (max-width: 768px) {
      order: 2;
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 15px;
  }
`;
const IframeWrapper = styled.iframe`
  height: 900px;
  border: unset;
  margin-top: 20px;
`;
export default SectionOne;
